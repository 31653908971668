import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SideNavService {
  private toggleNavSource = new Subject();
  private closeNavSource = new Subject();
  private navClosingSource = new Subject();

  toggleNav$ = this.toggleNavSource.asObservable();
  closeNav$ = this.closeNavSource.asObservable();
  navIsClosing$ = this.navClosingSource.asObservable();

  toggleNav = () => this.toggleNavSource.next();
  closeNav = () => this.closeNavSource.next();

  navIsClosing = () => {
    console.log('in nav serverice, triggering nav is closing')
    this.navClosingSource.next();
  }

}
