import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sell-tip',
  templateUrl: './sell-tip.component.html',
  styleUrls: ['./sell-tip.component.css']
})
export class SellTipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
