import { Component } from '@angular/core';
import { ViewChild } from '@angular/core';
import { SideNavService } from './side-nav.service';
import { Post } from './posts/post.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('sidenav') sidenav; // get your template reference in the component
  constructor(private sideNavService: SideNavService) { }

  private sideNavSub;
  private sideNavCloseSub;

  ngOnInit() {
   this.sideNavSub = this.sideNavService.toggleNav$.subscribe(() => this.sidenav.toggle());
   this.sideNavCloseSub = this.sideNavService.closeNav$.subscribe(() => this.sidenav.close());
   this.sidenav.closedStart.subscribe(() => {
     console.log("nav closing observable event recieved");
    this.sideNavService.navIsClosing();
  });
  }

  ngOnDestroy() {
   this.sideNavSub.unsubscribe(); // clean your subscriptions
   this.sideNavCloseSub.unsubscribe();
  }

  toggleNav() {
    this.sideNavService.toggleNav();
  }

  closeNav(){
    this.sideNavService.closeNav();
  }

  // storedPosts: Post[] = [];

  // onPostAdded( post ) {
  //  this.storedPosts.push( post );
  // }
}
