import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('text_in_right',[
      transition(':enter',[
        style({ transform: 'translateX(35%)' }),
        animate('0.2s',
          style({})
        )]
      )
    ]),
    trigger('text_in_left',[
      transition(':enter',[
        style({ transform: 'translateX(-35%)' }),
        animate('0.2s',
          style({})
        )]
      )
    ])
  ]
})
export class HomeComponent implements OnInit {

  constructor() { }

  showA: boolean = false;
  showB: boolean = false;
  showC: boolean = false;

  ngOnInit() {

  }

  defaultImage = 'https://www.placecage.com/1000/1000';
  image1 = '../../assets/img/all/chat_2.jpg';
  image2 = '../../assets/img/all/handshake.jpg';
  image3 = '../../assets/img/all/sold_3.jpg';
  offset = 100;

  public slideInSectionA({ target, visible }: { target: Element; visible: boolean }): void{
    if( visible )
      {
      this.showA = true;
      console.log("A IN VIEW");
      }
  }

  public slideInSectionB({ target, visible }: { target: Element; visible: boolean }): void{
    if( visible )
      {
      this.showB = true;
      console.log("B IN VIEW");
      }
  }

  public slideInSectionC({ target, visible }: { target: Element; visible: boolean }): void{
    if( visible )
      {
      this.showC = true;
      console.log("C IN VIEW");
      }
  }


}
