
import { Component  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material';
import { SideNavService } from '../side-nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  constructor( private sideNavService: SideNavService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
        'arrow-drop-down',
        sanitizer.bypassSecurityTrustResourceUrl('../../assets/img/icons/arrow_drop_down.svg'));
  }

  private sideNavIsClosingSub;

  ngOnInit() {
    this.sideNavIsClosingSub = this.sideNavService.navIsClosing$.subscribe( ()=>{
    });

   };

  ngOnDestroy() {
    // clean your subscriptions
    this.sideNavIsClosingSub.unsubscribe();
   }

  toggleNav() {
    this.sideNavService.toggleNav();
    console.log("Toggle the Sidebar");
  }

  closeNav(){
    console.log("Close the Sidebar");
    this.sideNavService.closeNav();
  }
}
