import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mistakes-tip',
  templateUrl: './mistakes-tip.component.html',
  styleUrls: ['./mistakes-tip.component.css']
})
export class MistakesTipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
