import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PreviewListing } from './preview-listing.model';
import { ListingsService } from './listings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.css']
})
export class ListingsComponent implements OnInit, OnDestroy {
  @Input() listings: PreviewListing[] = [];
  private listingsSub: Subscription;

  constructor( public listingsService: ListingsService ){ }

  ngOnInit() {
    this.listings = this.listingsService.getListings();
    this. listingsSub = this.listingsService.getListingUpdateListener()
      .subscribe((listings:PreviewListing[]) =>{
        console.log("INIT");
        this.listings = listings;
      });
    console.log("LOG MSG");
  }

  ngOnDestroy(){
    this.listingsSub.unsubscribe();
  }

}


/*
export interface PreviewListing {
  coverImageURL: string;
  buildingType: string;
  price: number;
  addressLineOne: string;
  city: string;
  state: string;
  zipCode: string;
  numBeds: number;
  numBaths: number;
  sqft: number;
  favorited: boolean;
}
*/
