import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { PreviewListing } from './preview-listing.model';
import { Subject } from 'rxjs';
/*
const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Bearer 2blfqtnzg78lgphcbgkdb1l7j',
    Accept: 'application/json',
    'X-SparkAPI-User-Agent': 'AZPLTESTSITE',
  })
};
*/

@Injectable({ providedIn:'root' })
export class ListingsService{
  /* Want to store an array of preview listings*/
  private listings: PreviewListing[] = [];
  private listingsUpdated = new Subject<PreviewListing[]>();

  /* Constructor */
  constructor(private http: HttpClient) {}

  getListings(){
    /* return a copy of listings */
    //return [...this.listings];

    this.http.get<{count: number, listings: PreviewListing[]}>('http://localhost:3000/api/search/' /*, httpOptions*/ )
      .subscribe((listingData) => {
        console.log("LISTINGS DATA COUNT");
        console.dir(listingData);

        console.log(listingData.count);

        for( let i = 0; i < listingData.count; i++){
          console.log("LOOP ITER")
          this.addListing(
            listingData.listings[i].coverImageURL,
            listingData.listings[i].buildingType,
            listingData.listings[i].price,
            listingData.listings[i].addressLineOne,
            listingData.listings[i].city,
            listingData.listings[i].state,
            listingData.listings[i].zipCode,
            listingData.listings[i].numBeds,
            listingData.listings[i].numBaths,
            listingData.listings[i].sqft,
            listingData.listings[i].favorited);
        }

    });
    return [...this.listings];
  }


  getListingUpdateListener(){
    console.log("get update lisner");
    return this.listingsUpdated.asObservable();
  }


  /* Adds a listing to the listings array */
  addListing(
      coverImageUrl: string,
      buildingType: string,
      price: number,
      addressLineOne: string,
      city: string,
      state: string,
      zipCode: string,
      numBeds: number,
      numBaths: number,
      sqft: number,
      favorited: boolean) {
        const listing: PreviewListing = {
          coverImageURL: coverImageUrl,
          buildingType: buildingType,
          price: price,
          addressLineOne: addressLineOne,
          city: city,
          state: state,
          zipCode: zipCode,
          numBeds: numBeds,
          numBaths: numBaths,
          sqft: sqft,
          favorited: favorited
        };

        console.log("ADD LISTING");

        /* Push new listing to the listings arr */
        this.listings.push(listing);
        this.listingsUpdated.next([...this.listings]);

  }

  getListingsAtLocation(){
  }


}
