import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatInputModule,
  MatCardModule,
  MatButtonModule,
  MatToolbarModule,
  MatExpansionModule,
  MatMenuModule,
  MatIconModule,
  MatSidenavModule,
  MatGridListModule,
  MatDividerModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatSelectModule
       } from '@angular/material';
  import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
  import { InViewportModule } from 'ng-in-viewport';


import { AppComponent } from './app.component';
import { PostCreateComponent } from './posts/post-create/post-create.component';
import { HeaderComponent } from './header/header.component';
import { PostListComponent } from './posts/post-list/post-list.component';
import { HomeComponent } from './pages/home/home.component';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { LoginComponent } from './pages/auth/login/login.component';
import { SignupComponent } from './pages/auth/signup/signup.component';
import { FooterComponent } from './footer/footer.component';
import { ListingsComponent } from './reusable/listings/listings.component';
import { BuyComponent } from './pages/buy/buy.component';
import { SellComponent } from './pages/sell/sell.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TipsComponent } from './pages/tips/tips.component';
import { BuyTipComponent } from './pages/tips/sub/buy-tip/buy-tip.component';
import { SellTipComponent } from './pages/tips/sub/sell-tip/sell-tip.component';
import { MoveTipComponent } from './pages/tips/sub/move-tip/move-tip.component';
import { MistakesTipComponent } from './pages/tips/sub/mistakes-tip/mistakes-tip.component';
import { SocialBarComponent } from './reusable/social-bar/social-bar.component';

@NgModule({
  declarations: [
    AppComponent,
    PostCreateComponent,
    HeaderComponent,
    FooterComponent,
    PostListComponent,
    HomeComponent,
    routingComponents,
    LoginComponent,
    SignupComponent,
    ListingsComponent,
    BuyComponent,
    SellComponent,
    ContactComponent,
    TipsComponent,
    BuyTipComponent,
    SellTipComponent,
    MoveTipComponent,
    MistakesTipComponent,
    SocialBarComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    HttpClientModule,
    MatMenuModule,
    MatIconModule,
    FlexLayoutModule,
    MatSidenavModule,
    AppRoutingModule,
    MatGridListModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSelectModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
    }),
    InViewportModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
