import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SellerInfo } from './seller_info.model'
import { NgForm, FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

export interface State {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.css']
})
export class SellComponent implements OnInit {

  comment: string;

  isLoading = false;
  formSubmitted = false;

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  states: State[] = [
    {value: 'AZ', viewValue: 'Arizona'},
  ];

    // properties
    @Output() newSellerInfo = new EventEmitter<SellerInfo>();

    onSubmitInfo(form: NgForm) {
      if ( form.invalid ) {
        return;
      }

      this.isLoading = true;

      const sellerInfo: SellerInfo = {
        firstName: form.value.firstname,
        lastName: form.value.lastname,
        email: form.value.email,
        phone: form.value.phone,
        address: form.value.address1,
        address_2: form.value.address2,
        city: form.value.city,
        state: 'Arizona',
        zip: form.value.zip,
        price: form.value.price,
        year: form.value.year,
        beds: form.value.beds,
        baths: form.value.baths,
        comment: this.comment
      };
      this.newSellerInfo.emit( sellerInfo );

      this.http.post<{message: string}>('https://azpropertyladies.com/sell', sellerInfo)
        .subscribe((responseData) => {

          this.formSubmitted = true;
          this.isLoading = false;
          console.log(responseData);
        });
    }


}
