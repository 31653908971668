import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buy-tip',
  templateUrl: './buy-tip.component.html',
  styleUrls: ['./buy-tip.component.css']
})
export class BuyTipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
